.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.height_100 {
  height: 100%;
}

.pb_thumbnail {
  padding-top: 56.25%;
}

.hidden {
  display: none;
}

.pb_min_width_350 {
  min-width: 350px;
}

.pb_draggable_dialog_element {
  z-index: 1301;
}

.pb_admin_appbar {
  z-index: 1201 !important;
}

.pb_video_gallery_root {
  display: flex;
}

.pb_drawer_parent {
  flex-shrink: 0;
  width: 200px;
}

.pb_tags_drawer {
  width: 200px;
}

.video-card {
  flex-basis: 25%;
}

.center_typography {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
